<template>
  <v-app class="grey darken-4">
    <core-app-bar />

    <core-downloads />

    <core-drawer />

    <core-view />

    <core-snackbar />
  </v-app>
</template>

<script>
export default {
  components: {
    CoreAppBar: () => import("@/components/core/AppBar"),
    CoreDownloads: () => import("@/components/core/Downloads"),
    CoreDrawer: () => import("@/components/core/Drawer"),
    CoreSnackbar: () => import("@/components/core/Snackbar"),
    CoreView: () => import("@/components/core/View"),
  },
};
</script>

<style>
.v-card {
  border-radius: 6px;
}
a {
  text-decoration: none;
}
</style>
