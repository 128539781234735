export default {
  namespaced: true,

  state: {
    featured: [],
    codes: [
      // {
      //   id: 'ddong',
      //   img: 'codes/ddong/bg1.png',
      //   altImg: 'codes/ddong/bg1.png',
      //   needAltImg: false,
      //   title: '똥피하기',
      //   genre: 'Unity',
      //   content: [
      //     {
      //       type: 'p',
      //       content: 'Dragon Forest는 사천성 퍼즐과 로그라이크를 결합하였습니다.'
      //     },
      //     {
      //       type: 'p',
      //       content: '좋은 피드백이든 나쁜 피드백이든 얼마든지 주세요. 혼자 개발하는 건 외롭네요ㅋㅋ'
      //     },
      //     {
      //       type: 'link',
      //       link: 'https://store.steampowered.com/app/2272140/Dragon_Forest/?beta=0',
      //       content: 'Steam에서 확인'
      //     },

      //     {
      //       type: 'p',
      //       content: '찜하기를 눌러 소식을 받아보세요!'
      //     },
      //   ],
      // },


      {
        id: 'nodejs',
        img: 'codes/common/node_bg.jpg',
        altImg: 'codes/common/steam_logo.jpg',
        needAltImg: false,
        title: 'Node.js',
        genre: 'Unity',
        content: [
          {
            type: 'p',
            content: 'nodejs post'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/5',
            content: 'Node 프로젝트 만들기 (Express)'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/7',
            content: 'java의 class 처럼 모듈 빼서 사용하기'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/8',
            content: 'binance api 사용하기 - candle 정보 읽어오기'
          },

          {
            type: 'link',
            link: 'https://mangveloper.com/9',
            content: 'post 구현'
          },

          {
            type: 'link',
            link: 'https://mangveloper.com/16',
            content: 'Firestore에 정보 저장하기(Firebase 연동)'
          },
        ],
      },
      {
        id: 'vuejs',
        img: 'codes/common/steam_logo.jpg',
        altImg: 'codes/common/vue_bg.jpg',
        needAltImg: false,
        title: 'Vue & Html',
        genre: 'Unity',
        content: [
          {
            type: 'p',
            content: 'nodejs post'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/6',
            content: 'Vue, vuetify 프로젝트 만들기 (1)'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/13',
            content: 'Vue와 robots.txt'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/17',
            content: 'vue multi page build'
          },

          {
            type: 'link',
            link: 'https://mangveloper.com/9',
            content: 'post 구현'
          },

          {
            type: 'link',
            link: 'https://mangveloper.com/18',
            content: 'html 카카오톡 공유 api 개발하는 방법'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/19',
            content: 'vue progress bar 만들기'
          },
        ],
      },
      {
        id: 'unity',
        img: 'codes/common/unity_bg.jpg',
        altImg: 'codes/common/steam_logo.jpg',
        needAltImg: false,
        title: 'Unity Post',
        genre: 'Unity',
        content: [
          {
            type: 'p',
            content: '유니티 관련 post'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/2',
            content: '복불복 게임 소스 공유(Chwazi)'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/24',
            content: 'TextMesh line 수 확인 및 update 타이밍'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/25',
            content: 'Textmesh 배경 자동 크기변경, resize'
          },
        ],
      },
      {
        id: 'admob',
        img: 'codes/common/admob_bg.jpg',
        altImg: 'codes/common/admob_bg.jpg',
        needAltImg: false,
        title: 'Admob',
        genre: 'Unity',
        content: [
          {
            type: 'p',
            content: '애드몹을 앱에 적용하는 POST 들'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/31',
            content: '기본 설정 및 배너 광고 (android)'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/32',
            content: '전면 광고 (android)'
          },
          {
            type: 'git',
            link: 'https://github.com/makegametogether/AdmobSample',
            content: '배너 및 전면 광고 sample code'
          },
        ],
      },
      {
        id: 'steam',
        img: 'codes/common/steam_logo.jpg',
        altImg: 'codes/common/steam_logo.jpg',
        needAltImg: false,
        title: '스팀',
        genre: 'Unity',
        content: [
          {
            type: 'p',
            content: '스팀에 가입 및 앱을 올리는 법 등을 다루고 있다.'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/21',
            content: '게임 출시 하는 법'
          },

          {
            type: 'link',
            link: 'https://mangveloper.com/23',
            content: '출시 예정 페이지 만들어야 하는 이유 - 스팀 마케팅 1'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/26',
            content: '출시 예정 페이지 만드는법'
          },

          {
            type: 'link',
            link: 'https://mangveloper.com/30',
            content: '데모 페이지 & 빌드 올리기'
          },
        ],
      },
      {
        id: 'coin',
        img: 'codes/common/binance_logo.jpg',
        altImg: 'codes/common/steam_logo.jpg',
        needAltImg: false,
        title: 'Coin bot - binance',
        genre: 'Unity',
        content: [
          {
            type: 'p',
            content: '코인관련 post'
          },
          {
            type: 'link',
            link: 'https://mangveloper.com/8',
            content: 'candle 정보 읽어오기'
          },
        ],
      },
    ],
  },

  getters: {
    parsedCodes: state => {
      return state.codes.map(code => ({
        ...code,
      }))
    },
  },
}
