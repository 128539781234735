export default {
  namespaced: true,

  state: {
    articles: [
      {
        id: 'dragon_forest_demo',
        img: 'home/steam_library_hero_3840-1240.png',
        altImg: 'home/steam_screenshot_1920-1080_1.png',
        needAltImg: true,
        title: 'Dragon Forest Demo 출시!',
        author: 'John Smith',
        subTitle: '개발자망고가 퇴사를 위해 개발 중인 게임',
        content: [
          {
            type: 'p',
            content: 'Dragon Forest는 사천성 퍼즐과 로그라이크를 결합하였습니다.'
          },
          {
            type: 'p',
            content: '좋은 피드백이든 나쁜 피드백이든 얼마든지 주세요. 혼자 개발하는 건 외롭네요ㅋㅋ'
          },
          {
            type: 'link',
            link: 'https://store.steampowered.com/app/2272140/Dragon_Forest/?beta=0',
            content: 'Steam에서 확인'
          },

          {
            type: 'p',
            content: '찜하기를 눌러 소식을 받아보세요!'
          },
        ],
      },
      // {
      //   img: 'articles/article2a.png',
      //   altImg: 'articles/article2b.png',
      //   title: '10 Indie Games to Watch in 2019',
      //   slug: 'alienware-area-52',
      //   author: 'John Smith',
      //   ago: '12h ago',
      // },
    ],
  },
  getters: {
    parsedArticles: state => {
      return state.articles.map(item => ({
        ...item,
      }))
    },
  },
}
